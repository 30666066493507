import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wave-anim',
  templateUrl: './wave-anim.component.html',
  styleUrls: ['./wave-anim.component.css']
})
export class WaveAnimComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
