import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jupiter-privacy-policy',
  templateUrl: './jupiter-privacy-policy.component.html',
  styleUrls: ['./jupiter-privacy-policy.component.css']
})
export class JupiterPrivacyPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
